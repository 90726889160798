import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"
export const PNGtoPdf = (
  data,
  setDownload,
  setUploadPct,
  setProcessPct,
  setDownloadPct
) =>
  client
    .post("/upload", data.formData, {
      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setUploadPct(percentCompleted)
      },
    })
    .then(res => {
      DataLayer("Online tool", "Upload", "Png to pdf")
      client
        .get(`/convertImageTool?fileName=${data.fileName}`)
        .then(res => {
          DataLayer("Online tool", "Convert", "Png to pdf")
          client
            .get(`/downloadImages2PDF?fileName=${data.fileName}`, {
              responseType: "blob",
              onDownloadProgress: progressEvent => {
                var percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )
                setDownloadPct(percentCompleted)
              },
            })
            .then(res => {
              setProcessPct(100)
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download = `pdfpro_images.pdf`
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "Png to pdf")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "Png to pdf")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error convert", "Png to pdf")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "Png to pdf")
    })
